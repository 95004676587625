.cta-container {
    text-align: center;
    margin-top: 10px;
}

#inner-content p {
    margin: 0em!important;
}

.text-content {
    overflow-wrap: break-word;
}

.ql-editor {
    box-sizing: unset!important;
    line-height: unset!important;
    height: unset!important;
    outline: none!important;
    overflow-y: unset!important;
    padding: unset!important;
    tab-size: unset!important;
    -moz-tab-size: unset!important;
    text-align: unset!important;
    white-space: unset!important;
    word-wrap: unset!important;
}