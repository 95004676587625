@font-face {
    font-family: 'Avenir Next Rounded';
    src: url('./assets/fonts/AvenirNextRoundedStd-Bold.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextRoundedStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Rounded';
    src: url('./assets/fonts/AvenirNextRoundedStd-Reg.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextRoundedStd-Reg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Rounded';
    src: url('./assets/fonts/AvenirNextRoundedStd-Italic.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextRoundedStd-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Rounded';
    src: url('./assets/fonts/AvenirNextRoundedStd-Med.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextRoundedStd-Med.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Rounded';
    src: url('./assets/fonts/AvenirNextRoundedStd-Demi.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextRoundedStd-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Rounded';
    src: url('./assets/fonts/AvenirNextRoundedStd-Italic.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextRoundedStd-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Rounded';
    src: url('./assets/fonts/AvenirNextRoundedStd-Med.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextRoundedStd-Med.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Rounded';
    src: url('./assets/fonts/AvenirNextRoundedStd-Reg.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextRoundedStd-Reg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Avenir Next Rounded', sans-serif;
}

button {
    font-family: 'Avenir Next Rounded', sans-serif;
}

textarea {
    font-family: 'Avenir Next Rounded', sans-serif;
}