.no-internet-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #fff; /* Adjust the background color as needed */
}

.no-internet-image {
    width: 200px; /* Adjust the size as needed */
    margin-bottom: 20px;
}

h1 {
    color: #ef5c34; /* Match the color from the image */
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: lowercase;
}

p {
    /* Adjust the color as needed */
    font-size: 16px;
    margin: 0.4em 0.4em;
}

.retry-button {
    background-color: #ef5c34; /* Match the button color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    text-transform: lowercase;
}

.retry-button:hover {
    background-color: #d94e2b; /* Slightly darker shade for hover effect */
}