.chat-header {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid hsl(0, 0%, 87%);
}

.header-back-button {
  margin-top: 4px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.header-back-button img {
  /* padding-top: 6px; */
  width: auto;
  height: 24px;
}

.chat-title-container {
  flex: 1;
}

.chat-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  /*padding-top: 20px;*/
}

.chat-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.chat-icon {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.chat-icon-placeholder {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
}

.chat-header-label {
  position: absolute;
  bottom: -5px; /* Adjust this value to control the overlap */
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffe1d9;
  color: #ef5c34;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  border-radius: 12px;
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping */
  width: max-content; /* Adjust the width to fit the text content */
  max-width: 100%; /* Ensure it doesn't exceed the container */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}

.label-icon-parent {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.chat-title-label {
  background-color: #feeee9;
  color: #0000008f;
  padding: 4px 6px;
  font-size: 12px;
  border-radius: 16px;
  align-items: center;
}

.header-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56);
  margin: 0;
}

.chat-header-active-hours {
  color: #0000008f;
  font-size: 12px;
  margin-top: 2px;
}

.header-container {
  display: flex;
  align-items: center;
}