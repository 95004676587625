.channel-list {
    margin-top: 20px;
}

.no-chats-message {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.orange-highlight {
  color: #EF5C34;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.inifinite-scroll-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infinite-scroll-spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #EF5C34;
  animation: spin 1s linear infinite;
}