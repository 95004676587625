.iframe-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.iframe-modal-content {
    background: black;
    width: 90%;
    height: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.iframe {
    flex: 1;
    border: none;
    width: 100%;
}

.close-button {
    background-color: #EF5C34;
    color: white;
    border: none;
    padding: 10px;
    text-align: center;
    width: 80%;
    font-size: 16px;
    cursor: pointer;
    border-radius: 16px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}