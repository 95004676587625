html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.home-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 95%;
}

.home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url('../../assets/images/header-bg.svg');
    background-size: cover; /* Ensures the background image covers the entire area */
    background-repeat: no-repeat; /* Ensures the background image does not repeat */
    height: 120px; /* Adjust height as needed */
    padding: 10px 20px;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 95%;
    z-index: 1000; /* Ensure the header is above other content */
}

.search-bar {
    display: flex;
    align-items: center;
    width: 100%;
}

.search-bar input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    margin-right: 10px;
}

.search-icon,
.close-icon {
    cursor: pointer;
    background: none;
    border: none;
    margin-right: 10px;
}

.search-icon-image,
.close-icon-image {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.spinner {
    width: 24px;
    height: 24px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.search-results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 50%;
}


.search-results-container img {
    max-width: 100%;
    margin-bottom: 0px;
}

.chat-header-home {
    font-size: 30px;
    font-weight: 600;
}

.content {
    flex: 1;
    width: 100%;
    margin-top: 120px;
}
.chat-top-header {
    display: flex;
    flex-direction: row;
    flex:1;
    justify-content: space-between;
}

.chat-heading-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -10px;
}

.home-back-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:4px;
    margin-left:10px;
}