.moments-vertical-line-me {
  height: 70px;
  background-color: #ff6a33;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 14px;
  min-width: 3px;
  max-width: 3px;
}

.moments-vertical-line-not-me {
  height: 70px;
  background-color: #FBF5ED;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 14px;
  min-width: 3px;
  max-width: 3px;
}

.cta-body {
  margin-bottom: 10px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em; 
  max-height: 3.6em;
}

.image-moments-container {
  display: flex;
  align-items: center;
}

.moments-media-preview {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  object-fit: cover;
}