.moments-vertical-line-me{
  height: 70px;
  background-color: #FBF5ED;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 14px;
  min-width: 3px;
  max-width: 3px;
}

.moments-vertical-line-not-me{
  height: 70px;
  background-color: #ff6a33;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 14px;
  min-width: 3px;
  max-width: 3px;
}

.cta-body {
  margin-bottom: 10px;
  margin-top: 10px;
}

.video-moments-container {
  display: flex;
  align-items: center;
}

.moments-media-preview {
  min-width: 80px;
  min-height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  object-fit: cover;
  background-color: white;

}

.video-play-button {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 15px;
  left: 5px;
}

.video-container {
  position: relative;
  display: flex;
}

.video-thumbnail-container {
  overflow: hidden;
  display: none;
}