.centre-chat-item{
  display: flex;
  padding: 20px 5px;
  border-bottom: 1px solid #eee;
  text-decoration: none;
  color: inherit;
  margin-left: 5px;
}

.centre-chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.centre-chat-headings {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.centre-chat-title {
  font-size: 16px;
  font-weight: 500;
  color: #1E1E1EDB;
  margin: 0;
}

.centre-chat-subtitle {
  font-size: 14px;
  font-weight: 500;
  /* font-style: italic; */
  color: #EF5C34;
  margin: 0;
}

.centre-chat-unread-badge{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EF5C34;
  color: #FEFEFE;
  border-radius: 50%; /* Makes it circular */
  width: 24px; /* Ensure the badge is a perfect circle */
  height: 24px; /* Ensure the badge is a perfect circle */
  font-size: 12px;
  text-align: center; /* Center the text inside */
  padding: 0; /* Remove padding to maintain the circular shape */
  font-weight: 500;
}

.channel-last-message {
  font-size: 14px;
  color: #0000008F;
  overflow: hidden;          /* Hides any overflow content */
  text-overflow: ellipsis;   /* Adds ellipsis (...) for overflow content */
  margin: 0;
  display: -webkit-box;      /* Required for webkit browsers */
  -webkit-line-clamp: 1;     /* Specifies the number of lines */
  -webkit-box-orient: vertical; /* Required for webkit browsers */

  p {
    margin: unset;
  }
}

.channel-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.last-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.read-status-icon {
  padding-left: 5px;
  padding-top: 5px;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}