.poh-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 10px;
}

.avatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #EF5C34;
}

.avatar-message {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 2px;
  padding-right: 2px;
}

.poh-message-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;
  max-width: 250px;
}

.message-text {
  font-size: 14px;
  font-weight: 400;
  color: black;
  text-align: left;
  margin-left: 10px;
}

.link-text {
  font-size: 14px;
  font-weight: 400;
  color: #EF5C34;
  text-decoration: underline;
  align-self: flex-start;
}

.timestamp {
  font-size: 10px;
  color: #0000008F;
  margin-top: 5px;
  padding-left: 10px;
}

.line {
  border: none;
  border-top: 1px solid #c2c2c2;
  /* width: 100%; */
  min-width: 10%;
  margin: 0;
  flex-grow: 1;
}