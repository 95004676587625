.message {
  display: flex;
  align-items: flex-end; /* Align items to the bottom */
  margin-bottom: 30px;
  position: relative;
}

.message-body {
  white-space: pre-wrap; /* This will handle the newline characters correctly */
}

.message.me {
  justify-content: flex-end;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
}

.message.not-me {
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
}

.message-icon-container {
  display: flex;
  align-items: flex-end; /* Align icon to the bottom */
  margin-bottom: 1px; /* Space between icon and message content */
}

.message-content {
  max-width: 70%;
  padding: 2px 10px;
  border-radius: 16px;
  position: relative;
  font-size: 14px;
  white-space: pre-wrap;
}

.message.me .message-content {
  background-color: #E27557;
  text-align: left;
  border-bottom-right-radius: 0;
  color:white;
}

.message.not-me .message-content {
  background-color: #F7EEE9;
  text-align: left;
  border-top-left-radius: 0;
  color: #000000DB;
}

.parent-message {
max-width: 70%;
padding: 5px 10px;
border-radius: 16px;
position: relative;
font-size: 14px;
white-space: pre-wrap;
background-color: rgb(239, 92, 52);
color: white !important;
text-align: left;
border-bottom-left-radius: 0;
}

.message-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-icon-placeholder {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  color: white;
  margin-right: 10px;
}

.message-timestamp-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: #0000008F;
  margin-top: 5px;
}


.me-timestamp-container {
  justify-content: flex-end;
  align-self: flex-end;
}

.not-me-timestamp-container {
  justify-content: flex-start;
  align-self: flex-start;
}

.message-timestamp {
  margin-right: 5px;
}

.read-status-icon {
  padding-left: 5px;
  padding-top: 5px;
  width: 16px;
  height: 16px;
}

.message.me .message-timestamp-container {
  position: absolute;
  bottom: -20px;
  right: 15px;
}

.message.not-me .message-timestamp-container {
  position: absolute;
  bottom: -20px;
  left: 15px;
}

.media-preview {
  max-width: 100%;
  height: auto;
}

.cta-button.not-me {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 2px;
  margin-right: 2px;
  color: #ef5c34;
  border-radius: 16px;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  text-transform: lowercase;
}

.cta-button.not-me:link,
.cta-button.not-me:visited,
.cta-button.not-me:hover,
.cta-button.not-me:active {
  color: #ef5c34;
}

.cta-button.me {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 2px;
  margin-right: 2px;
  color: #FBF5ED;
  border-radius: 16px;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  text-transform: lowercase;
}

.cta-button.me:link,
.cta-button.me:visited,
.cta-button.me:hover,
.cta-button.me:active {
    color: #FBF5ED;
}

.file-message {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 4px;
  margin: 4px;
}

.file-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.file-name {
  flex: 1;
  margin-right: 8px;
}

.download-icon img {
  width: 24px;
  height: 24px;
  margin-top: 4px;
}

.author-name {
font-size: 14px;
font-weight: 600;
color: #00000880;
margin-bottom: 3px;
padding-left: 15px;
}