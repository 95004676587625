.channel-item {
  display: flex;
  align-items: flex-start;
  padding: 10px 10px;
  border-bottom: 1px solid #eee;
  text-decoration: none;
  color: inherit;
  width: 95%;
  margin-left: 5px;
}

.chat-icon-container {
  position: relative;
  margin-right: 15px;
}

.chat-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-list-icon-placeholder {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  text-transform: uppercase;
}

.chat-thumbnail-labels {
  position: absolute;
  top: 35px;
  display: flex;
  background-color: #FFE1D9;
  color: #EF5C34;
  font-size: 9px;
  padding: 2px 2px;
  border-radius: 12px;
  width: 53px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center; /* Center text */
  font-weight: 500;
}

.channel-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.channel-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.channel-title {
  font-size: 16px;
  font-weight: 500;
  color: #1E1E1EDB;
  margin: 0;
  padding-bottom: 4px;
}

.chat-labels {
    display: flex;
    flex-wrap: wrap;
    font-size: 24px;
}

.chat-label {
  background-color: #FEEEE9;
  color: #0000008F;
  font-size: 14px;
  border-radius: 12px;
  padding: 4px 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.label-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.channel-last-message {
  font-size: 14px;
  color: #0000008F;
  overflow: hidden;          /* Hides any overflow content */
  text-overflow: ellipsis;   /* Adds ellipsis (...) for overflow content */
  margin: 0;
  display: -webkit-box;      /* Required for webkit browsers */
  -webkit-line-clamp: 1;     /* Specifies the number of lines */
  -webkit-box-orient: vertical; /* Required for webkit browsers */
}

.channel-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.channel-timestamp {
  font-size: 12px;
  color: #1E1E1E;
  flex-shrink: 0;
  margin-top: 4px;
}

.unread-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EF5C34;
  color: #FEFEFE;
  border-radius: 50%; /* Makes it circular */
  width: 24px; /* Ensure the badge is a perfect circle */
  height: 24px; /* Ensure the badge is a perfect circle */
  font-size: 12px;
  text-align: center; /* Center the text inside */
  padding: 0; /* Remove padding to maintain the circular shape */
  font-weight: 500;
}