.cta-container {
  text-align: center;
  margin-top: 10px;
}

.progress-report-container {
  display: flex;
  align-items: center;
}

.icon {
  width: 62px;
  height: 52px;
  margin-right: 10px;
}

.vertical-line {
  min-width: 3px;
  max-width: 3px;
  background-color: #ff6a33;
  margin-right: 10px;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 70px;
}

.cta-body {
  margin-bottom: 10px;
  margin-top: 10px;
}