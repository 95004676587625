/* MessageList.css */
.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    padding-left: 0px;
    padding-right:0px;
    background-color: #f9f9f9;
    scroll-behavior: smooth;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Add this CSS to smooth the loading experience */
.message {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.inifinite-scroll-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infinite-scroll-spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #EF5C34;
  animation: spin 1s linear infinite;
}