.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    height: 100vh;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

.profile-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 20px; /* Increase padding for better spacing */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.back-button {
    position: relative;
    z-index: 10;
    background: none;
    border: none;
    cursor: pointer;
    padding-left: 30px; /* Increase padding to enlarge clickable area */
    padding-top: 10px; /* Increase padding to enlarge clickable area */
    padding-bottom: 10px; /* Increase padding to enlarge clickable area */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:4px;
}

.back-icon {
    /* width: 36px; Increase icon size for better visibility */
    /* height: 36px; Increase icon size for better visibility */
}

.back-button img {
    width: 24px;
    height: 24px;
}

.profile-content {
    width: 100%;
    max-width: 600px;
    text-align: center;
    padding: 0 20px;
    margin-top: -50px; /* Overlap header */
    box-sizing: border-box;
}

.profile-image-container {
    position: relative;
    display: inline-block;
}

.profile-icon {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid white;
}

.profile-icon-placeholder {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #ccc;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    border: 5px solid white;
    font-family: 'Avenir Next Rounded', sans-serif;
    text-transform: lowercase;
}

.profile-icon-label {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffe1d9;
    color: #ef5c34;
    padding: 2px 6px;
    font-size: 20px;
    border-radius: 12px;
    text-align: center;
    width: auto;
    white-space: nowrap;
}

.profile-name {
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    color: #1e1e1e;
    text-transform: lowercase;
}

.profile-details {
    margin-top: -20px;
    padding-top: -20px;
    font-size: 14px;
    color: #7e7e7e;
}

.profile-description {
    background-color: white;
    border-radius: 16px;
    padding: 10px;
    width: 90%;
    box-sizing: border-box;
    align-self: center;
    margin: 30px auto;
    text-align: start;
    text-transform: lowercase;
}

.description-header {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #1e1e1e;
}

.description-body {
    font-size: 12px;
    color: #7e7e7e;
}

.profile-footer {
    width: 100%;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    margin-top: auto;
}

.go-to-chat-button {
    width: 100%;
    max-width: 600px;
    padding: 10px 20px;
    background-color: #ef5c34;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}

.chat-visibility {
    font-size: 12px;
    color: #7e7e7e;
    margin-top: 10px;
}

.profile-active-hours {
    color: #0000008F;
    font-size:14px;

}