.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eee;
  background-color: white;
}

.message-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  font-family: 'Avenir Next Rounded', sans-serif;
  resize: none; /* Prevent resizing */
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: 100px; /* Limit the height to 5 lines */
}

.message-input:focus {
  outline: none; /* Remove the default outline */
  border-color: #ef5c34; /* Your theme's orange color */
  box-shadow: 0 0 0 2px rgba(239, 92, 52, 0.3); /* Optional: Add a shadow for better visibility */
}

.send-button {
  background: none;
  border: none;
  cursor: pointer;
}

.send-button img {
  width: 24px;
  height: 24px;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #e0e0e0;
}

.message-input {
  flex: 1;
  border: none;
  padding: 8px;
  margin-right: 8px;
  resize: none;
  font-size: 14px;
  outline: none;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.attachment-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: color 0.2s;
}

.attachment-button:hover {
  color: #333;
}

.send-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button img {
  width: 24px;
  height: 24px;
}
